<template>
  <div class="logistics-all">
    <MHeader title="交易物流" />
    <div class="logistics-main m-padding">
      <div class="logistics-item" v-for="(item, index) in list" :key="index">
        <div v-if="item.delivery_method != 5" @click="readClick(item)">
          <p class="date">{{ item.created_at }}</p>
          <div class="item-main m-padding">
            <div class="item-top van-hairline--bottom">
              <img
                :src="
                  item.shops_info.shops_id == 1
                    ? require('@/assets/order/441589786465_.pic_hd@2x.png')
                    : require('@/assets/order/icon_dianpu@2x.png')
                "
                alt=""
                class="logo"
              />
              <span
                >{{ item.shops_info.title }}
                <span
                  class="presale"
                  v-if="item.shops_info.shops_id == 1"
                  >{{ item.is_presale == 0 ? "自营" : "预售" }}</span
                >
                <span class="presale" v-else-if="item.is_presale"
                  >{{ "预售" }}</span
                >
              </span>
              <img
                src="@/assets/order/icon-fanhui@2x.png"
                alt=""
                class="point"
              />
            </div>
            <div class="item-tip">
              您的订单已发货
              <span :class="item.is_red == 0 ? 'is_red' : 'no_red'">{{
                item.is_red == 0 ? "未读" : "已读"
              }}</span>
            </div>
            <div class="item-info">
              <img :src="item.pic_image.url" alt="" />
              <div class="info-content">
                <p>{{ item.name }}</p>
                <p>订单号：{{ item.order_number }}</p>
                <p>物流公司：{{ item.logis }}</p>
                <p class="copy_content">
                  物流单号：{{ item.logis_number
                  }}<span
                    v-if="item.logis_number"
                    @click="copyCarrierNumber()"
                    class="copy-button"
                    :data-clipboard-text="item.logis_number || '暂无物流单号'"
                    >复制</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else @click="readClick(item)">
          <p class="date">{{ item.created_at }}</p>
          <div class="item-main m-padding">
            <div class="item-top van-hairline--bottom">
              <img
                :src="
                  item.shops_info.shops_id == 1
                    ? require('@/assets/order/441589786465_.pic_hd@2x.png')
                    : require('@/assets/order/icon_dianpu@2x.png')
                "
                alt=""
                class="logo"
              />
              <span
                >{{ item.shops_info.title }}
                <span
                  class="presale"
                  v-if="item.shops_info.shops_id == 1"
                  >{{ item.is_presale == 0 ? "自营" : "预售" }}</span
                >
                <span class="presale" v-else-if="item.is_presale"
                  >{{  "预售" }}</span
                ></span
              >
              <img
                src="@/assets/order/icon-fanhui@2x.png"
                alt=""
                class="point"
              />
            </div>
            <div class="item-tip">
              您的订单已经可以自提啦！
              <span :class="item.is_red == 0 ? 'is_red' : 'no_red'">{{
                item.is_red == 0 ? "未读" : "已读"
              }}</span>
            </div>
            <div class="item-info">
              <img :src="item.pic_image.url" alt="" />
              <div class="info-content">
                <p>{{ item.ename }}</p>
                <p class="copy_content">
                  订单号：{{ item.order_number }}
                  <span
                    v-if="item.logis_number"
                    @click="copyCarrierNumber()"
                    class="copy-button"
                    :data-clipboard-text="item.logis_number || '暂无物流单号'"
                    >复制</span
                  >
                </p>
                <p class="address">
                  请在我们工作时间来拿哦~ <br />
                  Mon-Fri 10AM-6PM Sat 10AM-3PM, Sun Closed Unit 133-11121
                  horseshoe way, Richmond,V7A5G7,BC,CA
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-if="noMore" class="search-no">暂无更多...</p>
    </div>
  </div>
</template>


<script>
import MHeader from "@/components/zh/m-header.vue";
import { ship_news, ship_red } from "@/api/zh/message.js";
import Clipboard from "clipboard";
export default {
  components: { MHeader },
  data() {
    return {
      list: [],
      copyEnd: true,
      noMore: false
    };
  },
  methods: {
    getData() {
      this.noMore = false
      ship_news().then((res) => {
        if (res) {
          this.list = res.data.data;
        } else {
          this.noMore = true
        }
      });
    },
    copyCarrierNumber() {
      this.copyEnd = false;
      setTimeout(() => {
        this.copyEnd = true;
      }, 0);
      var clipboard = new Clipboard(".copy-button");
      clipboard.on("success", () => {
        this.$notify({ type: "success", message: "复制成功" });
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$notify({ type: "warning", message: "该浏览器不支持复制" });
      });
      // event.preventDefault()
    },
    readClick(item) {
      if (item.is_red == 0) {
        ship_red({ id: item.id }).then((res) => {
          if (res) {
            console.log(res);
            // this.$notify({ type: "success", message: res.msg })
          }
        });
      }
      if (this.copyEnd) {
        this.$router.push({ path: `/zh/order/detail/${item.order_id}` });
      }
    },
  },
  created() {
    this.getData();
  },
  activated() {
    this.getData();
  },
};
</script>

<style scoped lang="less">
.logistics-all {
  width: 100%;
  height: 100%;
  background-color: #fafcff;
  overflow: hidden;
  .logistics-main {
    margin-top: 44px;
    width: 100%;
    height: calc(100% - 44px);
    overflow-y: auto;
    .logistics-item {
      width: 100%;
      margin-top: 20px;
      .date {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 20px;
        margin-bottom: 10px;
      }
      .item-main {
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        box-sizing: border-box;
        display: block;
        position: relative;
        .item-top {
          width: 100%;
          height: 42px;
          line-height: 40px;
          .presale {
            display: inline-block;
            font-size: 12px;
            height: 17px;
            width: 50px;
            line-height: 17px;
            position: relative;
            top: -2px;
            background-color: rgb(234, 88, 51);
            font-weight: normal;
            text-align: center;
            color: white;
            border-radius: 10px;
          }
          .logo {
            width: 16px;
            height: 14px;
          }
          span {
            font-size: 16px;

            font-weight: 600;
            color: rgba(12, 2, 29, 1);
            line-height: 22px;
            margin-left: 8px;
          }
          .point {
            float: right;
            width: 19px;
            height: 19px;
            transform: rotate(180deg);
            margin-top: 10px;
          }
        }
        .item-tip {
          margin: 8px 0;
          font-size: 14px;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 20px;
          position: relative;
          .is_red {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 12px;
            color: red;
          }
          .no_red {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 12px;
            color: #ccc;
          }
        }
        .item-info {
          display: flex;
          padding-bottom: 16px;
          img {
            width: 60px;
            height: 60px;
            margin-top: 10px;
            margin-right: 8px;
            border-radius: 4px;
          }
          .info-content {
            flex: 1;
            width: 0;
            .copy_content {
              position: relative;
              span {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                display: inline-block;
                width: 40px;
                height: 14px;
                border-radius: 13px;
                border: 1px solid rgba(50, 132, 250, 1);
                font-size: 12px;
                font-weight: 400;
                color: #3284fa;
                line-height: 14px;
                text-align: center;
              }
            }
          }
          .address {
            overflow: auto !important;
            font-size: 12px;
            text-overflow: inherit;
            white-space: pre-line;
          }
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            line-height: 20px;
            &:nth-of-type(1) {
              font-size: 14px;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              line-height: 20px;
            }
            &:nth-of-type(2) {
              margin-top: 3px;
              font-size: 12px;
              color: rgba(102, 102, 102, 1);
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
.search-no {
  line-height: 80px;
  text-align: center;
  color: #888;
  font-size: 14px;
}
</style>
